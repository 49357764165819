window.addEventListener("load", function() {
  var lang = 'de';
  if (document.getElementsByTagName('html')[0].getAttribute('lang')) {
    lang = document.getElementsByTagName('html')[0].getAttribute('lang');
  }
  if (document.cookie.split(';').filter(function(item) {
      return item.indexOf('cookiebanner=allow') >= 0
  }).length) {
    ItsOk(lang);
  } else {
    var options = {
      'text': {
        'de': 'Diese Webseite verwendet Cookies. Cookies werden zur Benutzerführung verwendet und helfen dabei, diese Webseite zu verbessern. Durch die weitere Nutzung dieser Webseite erklären Sie sich mit unserer Cookie-Police einverstanden.',
        'en': 'This website uses cookies. Cookies are used for user guidance and help to improve this website. By continuing to use this website, you agree to our cookie policy.',
      },
      'more': {
        'de': 'Datenschutzerklärung',
        'en': 'Privacy',
      },
      'more_link': {
        'de': '/agb',
        'en': '/en/agb',
      },
      'btn_ok': {
        'de': 'Alle Akzeptieren',
        'en': 'OK',
      },
      'btn_abort': {
        'de': 'Ablehnen',
        'en': 'Reject',
      },
      'btn_more': {
        'de': 'Individuelle Einstellungen',
        'en': 'more',
      }
    };
    var cookiebanner = document.createElement('div');
    cookiebanner.setAttribute('id', 'cookiebanner');
    cookiebanner.style.position = 'fixed';
    cookiebanner.style.bottom = 0;
    cookiebanner.style.left = 0;
    cookiebanner.style.right = 0;
    cookiebanner.style.zIndex = 10000;
    cookiebanner.style.backgroundColor = '#fff';
    cookiebanner.style.padding = '7px 10px';
    cookiebanner.style.borderTop = '1px solid #284E8B';
    cookiebanner.style.color = '#000';
    cookiebanner.setAttribute('class', 'row');

    var footer = document.getElementsByClassName('footer')[0];
    footer.classList.add('cookie');
    var body = document.getElementsByClassName('body')[0];
    body.classList.add('cookie');

    var text = document.createElement('div');
    text.textContent = options.text[options.text[lang] ? lang : 'de'];
    text.setAttribute('class', 'col-md-8 col-sm-12');
    //text.style.float = 'left';
    //text.style.width = '70%';
    text.style.fontSize = '14px';

    var more = document.createElement('a');
    more.setAttribute('href', options.more_link[options.more_link[lang] ? lang : 'de']);
    more.setAttribute('target', '_blank');
    more.textContent = ' ' + options.more[options.more[lang] ? lang : 'de'];
    text.appendChild(more);
    cookiebanner.appendChild(text);

    var buttons = document.createElement('div');
    buttons.setAttribute('id', 'cookiebanner_buttons');
    buttons.setAttribute('class', 'col-md-4 col-sm-12');
    //buttons.style.float = 'right';
    //buttons.style.width = '30%';
    //buttons.style.textAlign = 'right';

    var ok = document.createElement('a');
    ok.setAttribute('href', '#');
    ok.classList.add('button');
    ok.classList.add('button-primary');
    ok.classList.add('px-4');
    ok.onclick = function(e) {
      e.preventDefault();
      var CookieDate = new Date;
      CookieDate.setFullYear(CookieDate.getFullYear() +10);
      document.cookie = 'cookiebanner=allow; expires=' + CookieDate.toUTCString() + '; path=/';
      console.log(document.cookie)
      document.getElementById('cookiebanner').remove();
      //document.getElementById('cookiebanner_padding').remove();
      ItsOk(lang);
    };
    ok.textContent = options.btn_ok[options.btn_ok[lang] ? lang : 'de'];
    ok.style.padding = '5px';
    buttons.appendChild(ok);

    var more = document.createElement('button');
    more.setAttribute('type', 'button');
    more.setAttribute('data-toggle', 'modal');
    more.setAttribute('data-target', '#cookie-individual');
    more.classList.add('button');
    more.classList.add('button-primary');
    more.classList.add('px-4');
    /*more.onclick = function(e) {
      e.preventDefault();
      var CookieDate = new Date;
      CookieDate.setFullYear(CookieDate.getFullYear() +10);
      document.cookie = 'cookiebanner=allow; expires=' + CookieDate.toUTCString() + '; path=/';
      console.log(document.cookie)
      document.getElementById('cookiebanner').remove();
      document.getElementById('cookiebanner_padding').remove();
      ItsOk(lang);
    };*/
    more.textContent = options.btn_more[options.btn_more[lang] ? lang : 'de'];
    more.style.padding = '5px';
    more.style.margin = '5px';
    buttons.appendChild(more);

    var abort = document.createElement('a');
    abort.setAttribute('href', '#');
    abort.classList.add('button');
    abort.classList.add('button-primary');
    abort.classList.add('px-4');
    abort.onclick = function(e) {
      e.preventDefault();
      document.getElementById('cookiebanner').remove();
      //document.getElementById('cookiebanner_padding').remove();
    };
    abort.textContent = options.btn_abort[options.btn_abort[lang] ? lang : 'de'];
    abort.style.padding = '5px';
    buttons.appendChild(abort);

    cookiebanner.appendChild(buttons);

    document.getElementsByClassName('page')[0].appendChild(cookiebanner);

  function deleteCookies() {
    var theCookies = document.cookie.split(';');
    for (var i = 0 ; i < theCookies.length; i++) {
      var cookie_session = 'PHPSESSID';
      if (theCookies[i].indexOf(cookie_session) != -1) {
        //do nothing
      } else {
        document.cookie = theCookies[i].split('=')[0] + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  }

  deleteCookies();

    /*var cookiebanner_padding = document.createElement('div');
    cookiebanner_padding.setAttribute('id', 'cookiebanner_padding');
    cookiebanner_padding.style.height = document.getElementById('cookiebanner').offsetHeight + 'px';
    document.body.appendChild(cookiebanner_padding);*/
  }

  function ItsOk() {
    var yt = document.getElementsByClassName('youtube-privacy');
    var iframe = '';
    for (var i = 0; i < yt.length; i++) {
      yt[i].querySelector('.youtube-privacy-placeholder').remove();
      yt[i].getAttribute('data-yt')
      iframe = document.createElement('iframe');
      iframe.setAttribute('src', 'https://www.youtube.com/embed/' + yt[0].getAttribute('data-yt') + '?autoplay=0&rel=0&origin=https://www.youtube.com/watch?v=' + yt[0].getAttribute('data-yt') + '?rel=0')
      iframe.setAttribute('allowfullscreen', 'allowfullscreen');
      if (yt[i].getAttribute('data-width')) {
        iframe.setAttribute('width', yt[i].getAttribute('data-width'));
      }
      if (yt[i].getAttribute('data-height')) {
        iframe.setAttribute('height', yt[i].getAttribute('data-height'));
      }
      yt[i].appendChild(iframe);
      aspectRatio();
    }
    /*var _paq = _paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//piwik.bithosting.eu/";
        _paq.push(['setTrackerUrl', u+'piwik.php']);
        _paq.push(['setSiteId', 9]);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
      })();*/
      var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
      var u="//piwik.bithosting.eu/";
      _paq.push(['setTrackerUrl', u+'piwik.php']);
      _paq.push(['setSiteId', 19]);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
      })();
      //document.cookie = "api=a919k76b0vf76m9t5vcdjmfjgb";
      var s = document.createElement("script");
      s.src = "https://www.google.com/recaptcha/api.js";
      $("head").append(s);
      var footer = document.getElementsByClassName('footer')[0];
      footer.classList.remove('cookie');
      var body = document.getElementsByClassName('body')[0];
      body.classList.remove('cookie');
      if (document.getElementById('cookie-notice')) {
        document.getElementById('cookie-notice').remove();
      }
  }

  var individual = document.getElementById('cookie-settings');
  individual.onclick = function(e) {
      e.preventDefault();
      var CookieDate = new Date;
      CookieDate.setFullYear(CookieDate.getFullYear() +10);
      document.cookie = 'cookiebanner=allow; expires=' + CookieDate.toUTCString() + '; path=/';
      console.log(document.cookie)
      document.getElementById('cookiebanner').remove();
      var footer = document.getElementsByClassName('footer')[0];
      footer.classList.remove('cookie');
      var body = document.getElementsByClassName('body')[0];
      body.classList.remove('cookie');

      if (document.getElementById('recaptcha').checked) {
        ReCaptchaOk(lang);
      }

      if (document.getElementById('youtube').checked) {
        YoutubeOk(lang);
      }

      if (document.getElementById('piwik').checked) {
        PiwikOk(lang);
      }
      closeOneModal('cookie-individual');
      document.body.className = document.body.className.replace("modal-open","");
  };

  function YoutubeOk()  {
    var yt = document.getElementsByClassName('youtube-privacy');
    var iframe = '';
    for (var i = 0; i < yt.length; i++) {
      yt[i].querySelector('.youtube-privacy-placeholder').remove();
      yt[i].getAttribute('data-yt')
      iframe = document.createElement('iframe');
      iframe.setAttribute('src', 'https://www.youtube.com/embed/' + yt[0].getAttribute('data-yt') + '?autoplay=0&rel=0&origin=https://www.youtube.com/watch?v=' + yt[0].getAttribute('data-yt') + '?rel=0')
      iframe.setAttribute('allowfullscreen', 'allowfullscreen');
      if (yt[i].getAttribute('data-width')) {
        iframe.setAttribute('width', yt[i].getAttribute('data-width'));
      }
      if (yt[i].getAttribute('data-height')) {
        iframe.setAttribute('height', yt[i].getAttribute('data-height'));
      }
      yt[i].appendChild(iframe);
      aspectRatio();
    }
  }

  function ReCaptchaOk() {
    var s = document.createElement("script");
    s.src = "https://www.google.com/recaptcha/api.js";
    $("head").append(s);
    //document.cookie = "api=a919k76b0vf76m9t5vcdjmfjgb";
  }

  function PiwikOk() {
    var _paq = _paq || [];
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
    var u="//piwik.bithosting.eu/";
    _paq.push(['setTrackerUrl', u+'piwik.php']);
    _paq.push(['setSiteId', 20]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
    })();
  }

  $(window).resize(function() {
    aspectRatio();
  });

  function aspectRatio() {
    /*var new_height = ($('.youtube-privacy').width() / (498 / 374));
    $('.youtube-privacy iframe').attr('height', new_height).attr('width', $('.youtube-privacy').width());*/
  }

  document.getElementById('cookiebanner-reset').onclick = function(e) {
    e.preventDefault();
    document.cookie = 'cookiebanner=; path=/';
    window.location.reload();
  }

  function closeOneModal(modalId) {

    // get modal
    var modal = document.getElementById(modalId);

    // change state like in hidden modal
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
     // get modal backdrop
     var modalBackdrops = document.getElementsByClassName('modal-backdrop');

     // remove opened modal backdrop
      document.body.removeChild(modalBackdrops[0]);
    $('.modal-backdrop').remove();
  }
});
